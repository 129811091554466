#loginDiv{
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
#loginDiv > div{
	max-width: 100vw;
	width: 300px;
}
#buttonDiv > *{
	width: 200px; 
	max-width: 100vw;
}
#buttonDiv{
	text-align: center;
}
