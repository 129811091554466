#my-map_map{
	/*height: 100%;*/
	height: 100%;
	width: 100vw;
}
#my-map_startTripBtn{
	position: absolute;
	width: 110px;
	height: 110px;
	top: 36px;
	right: 36px;
	cursor: pointer;
	border-radius: 50%;
	background: #53A66E;
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
}
#my-map_startTripBtn:hover{
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
#my-map_startTripBtn > div{
	position: absolute;
	height: 60px;
	width: 60px;
	top: 25px;
	left: 25px;
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: center;
	color: white;
}
.my-info-window img{
	max-width: 100%;
	height: unset !important;
}
.my-info-window{
	/*max-width: 100vw;
	width: 300px;*/
}