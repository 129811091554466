#mc-answer-container{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

#mc-answer-container > div{
	display: flex;
	flex: 48px 0;
	min-height: 48px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background: lightgray;
	border-radius: 10px;
	margin: 10px;
}

#mc-answer-container > div.activeAnswer{
	background: gray;
}
.task-html img, .task-html iframe{
	width: 100%;
	/*height: unset !important;*/
}